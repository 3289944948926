.phone-number-input {
  position: relative;
}

.phone-number-input input {
  padding-left: 65px !important;
  width: 100%;
  min-height: 41px;
  display: flex;
  align-items: center;
}

.phone-number-input .lc-text-field {
  margin-bottom: 0;
}

.phone-number-input .flag-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  min-height: 42px;
  left: 10px;
  z-index: 1;
}

.phone-number-input .flag-wrapper .prefix {
    margin-left: 5px;
}

.phone-number-input #phone-number::-webkit-outer-spin-button,
.phone-number-input #phone-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.phone-number-input #phone-number[type=number] {
  -moz-appearance: textfield;
}
